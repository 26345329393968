<template>
  <div>
    <div id="viewer">
      <div class="viewer-break" style="page-break-before: always !important;">
        <div>
          <table style="width:100%">
            <tr>
              <th>TỈNH ỦY ĐỒNG NAI</th>
              <th><u>ĐẢNG CỘNG SẢN VIỆT NAM</u></th>
            </tr>
            <tr style="text-align: center;">
              <td>
                <span>*</span><br />
                <span>Số - TB/TU</span>
              </td>
              <td>
                <span style="font-style: italic;"
                  >Đồng Nai, ngày tháng năm</span
                >
              </td>
            </tr>
          </table>
        </div>
        <div style="text-align: center;">
          <h2>Thông báo kết luận của...</h2>
          <h3>Nội dung cuộc họp</h3>
          <div
            v-for="(item, index) in summaryList"
            :key="item + index"
            style="text-align: left;"
          >
            <!-- Content -->
            <div>
              <h3>{{ romanize(index + 1) + "." }} {{ item.content }}</h3>
              <table style="width: 60%">
                <tr>
                  <td>Người báo cáo</td>
                  <td style="color: red;">{{ item.user_create.full_name }}</td>
                </tr>
                <tr>
                  <td>Trạng thái</td>
                  <td>{{ $t(item.status) }}</td>
                </tr>
                <tr>
                  <td>Thời gian báo cáo</td>
                  <td>{{ item.time_create }}</td>
                </tr>
              </table>
            </div>
            <!-- Task -->
            <div
              v-for="(taskItem, taskIndex) in item.task"
              :key="taskItem + taskIndex"
            >
              <div v-if="!isEmptyString(taskItem.content)">
                <p style="font-style: italic; font-weight: bold;">{{ taskIndex + 1 + "." }} {{ taskItem.content }}</p>
                <table style="width: 60%">
                  <tr>
                    <td>Người tiếp nhận</td>
                    <td>
                      <div
                        v-for="(userAssign, index) in taskItem.user_assgin"
                        :key="userAssign + index"
                      >
                        <span style="font-weight: bold;">{{
                          userAssign.full_name
                        }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Trạng thái</td>
                    <td>{{ $t(getTaskStatus(taskItem.status)) }}</td>
                  </tr>
                  <tr>
                    <td>Bắt đầu</td>
                    <td>{{ formatDate(taskItem.start_time) }}</td>
                  </tr>
                  <tr>
                    <td>Kết thúc</td>
                    <td>{{ formatDate(taskItem.end_time) }}</td>
                  </tr>
                </table>
              </div>
              <p v-else>Không có nhiệm vụ</p>
            </div>
          </div>
        </div>
        <div>
          <table style="width:100%">
            <tr>
              <th style="text-align: left;"><u>Nơi nhận</u></th>
              <th style="text-align: right;">
                <span style="font-style: italic;">T/L BAN THƯỜNG VỤ</span><br />
                <span style="font-style: italic; font-weight: normal;"
                  >CHÁNH VĂN PHÒNG</span
                >
              </th>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import functionUtils from "utils/functionUtils";
import { mapGetters } from "vuex";
import ActionSummaryType from "enum/actionSummaryType";
import stringUtils from "utils/stringUtils";
import dateUtils from "utils/dateUtils";
export default {
  props: {
    summaryList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => ({
    listSummaryStatus: stringUtils.SummaryStatus,
  }),
  computed: {
    ...mapGetters(["ACTION_SUMMARY_TYPE"]),
  },
  watch: {
    ACTION_SUMMARY_TYPE: function() {
      if (this.ACTION_SUMMARY_TYPE == ActionSummaryType.EXPORT) {
        this.generateWord();
        this.$store.state.summary.actionSummaryType = null;
      }
    },
  },
  methods: {
    /**
     * Format date
     */
    formatDate: function(date) {
      if (functionUtils.isNull(date)) {
        return null;
      }
      return dateUtils.formatDate(
        date,
        dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
        dateUtils.STATIC_FORMAT_DATE
      );
    },
    /**
     * Get task status
     */
    getTaskStatus: function(status) {
      return stringUtils.MissionStatus.filter((x) => x.value === status).map(
        (x) => x
      )[0].text;
    },
    /**
     * Convert number to romanize
     */
    romanize: function(num) {
      return functionUtils.romanize(num);
    },
    /**
     * Check empty string
     */
    isEmptyString: function(str) {
      return functionUtils.isEmptyString(str);
    },
    /**
     * Generate word
     */
    generateWord: function() {
      var header =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
        "xmlns:w='urn:schemas-microsoft-com:office:word' " +
        "xmlns='http://www.w3.org/TR/REC-html40'>" +
        "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
      var footer = "</body></html>";
      var sourceHTML =
        header + document.getElementById("viewer").innerHTML + footer;
      var source =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(sourceHTML);
      var fileDownload = document.createElement("a");
      document.body.appendChild(fileDownload);
      fileDownload.href = source;
      fileDownload.download = "thong bao ket luan.doc";
      fileDownload.click();
      document.body.removeChild(fileDownload);
    },
  },
};
</script>
