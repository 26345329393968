<template>
  <div class="container-fluid text-left">
    <div class="row" v-if="!showSummaryDocument">
      <!--  -->
      <div
        class="col-12 pl-0 pr-0"
        v-if="isSecretaryRole() || isChairmanRole()"
      >
        <div
          :class="
            checkShow == -1
              ? 'col-sm-12 title select pointer'
              : 'col-sm-12 title pointer'
          "
          style="display: flex; padding-top: 15px; padding-bottom: 15px"
          @click="showContentSummary(-1)"
        >
          <div class="class-center avatar-icon p-0 ml-1">
            <img src="../../assets/icons/Plus.svg" />
          </div>
          <div class="col-sm-6 class-center">
            {{ $t("summary.addNew") }}
          </div>
        </div>
      </div>
      <addnewsummary ref="showAddNewSummary"></addnewsummary>
      <!--  -->
      <div
        class="col-12 pl-0 pr-0"
        v-for="(item, index) in summaryList"
        :key="item + index"
      >
        <div
          :class="
            checkShow == index
              ? 'col-sm-12 p-2 title select pointer'
              : 'col-sm-12 p-2 title not-select pointer'
          "
          style="display: flex"
          @click="showContentSummary(index)"
        >
          <div
            class="class-center avatar-icon p-0 ml-2"
            style="
              width: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
            "
          >
            <!-- <div
              v-if="item.user_create.url_avatar"
              style="width: 30px; height: 30px; background-color: #e5e5e5; border-radius: 50%;"
            >
              <div class="circular--portrait">
                <img :src="item.user_create.url_avatar" alt="Avatar" class="circular--square" />
              </div>
            </div>-->
            <avatar
              v-if="item.user_create.url_avatar"
              :src="item.user_create.url_avatar"
              backgroundColor="#e5e5e5"
              color="#0097A7"
              :size="30"
            ></avatar>
            <avatar
              v-else
              :username="item.user_create.full_name"
              :backgroundColor="checkShow == index ? '#ffffff' : '#e5e5e5'"
              color="#0097A7"
              :size="30"
            ></avatar>
          </div>
          <div class="col-sm-6 class-center">
            {{ item.content }}
            <!-- </p> -->
          </div>
          <div class="col-sm-5 class-center p-0" style>
            <div class="col-sm-12 p-0" style="display: flex">
              <div
                class="col-sm-1 class-center item-center p-0"
                style="color: #26ba50"
              >
                {{ index + 1 }}/{{ summaryList.length }}
              </div>
              <div class="col-sm-10 p-0 item-center ml-3">
                <button
                  type="button"
                  class="btn"
                  style="
                    border: 1px solid #26ba50;
                    color: #26ba50;
                    padding: 2px 5px;
                    margin: 5px;
                  "
                  @click="onShowModalAddDocument(item.id)"
                >
                  <i class="fas fa-file-upload"></i>
                  {{ $t("summary.uploadDocument") }}
                </button>
                <button
                  disabled
                  type="button"
                  class="btn"
                  style="
                    border: 1px solid #26ba50;
                    color: #26ba50;
                    padding: 2px 5px;
                    margin: 5px;
                    cursor: not-allowed;
                  "
                >
                  <i class="fas fa-file-download mr-1"></i>
                  {{ $t("summary.downDocument") }}
                </button>
                <button
                  v-if="isSecretaryRole() || isChairmanRole()"
                  :disabled="item.task.length == 0 || item.done_all_task"
                  type="button"
                  class="btn"
                  :style="
                    item.task.length == 0 || item.done_all_task
                      ? 'border: 1px solid #26BA50; color: #26BA50; padding: 2px 5px; margin: 5px; cursor: not-allowed;'
                      : 'border: 1px solid #26BA50; color: #26BA50; padding: 2px 5px; margin: 5px'
                  "
                  @click="showModalConfirmSendEmailForMissionNotComplete(item)"
                >
                  <i class="fas fa-envelope mr-1"></i>
                  {{ $t("summary.sendEmail") }}
                </button>
              </div>
              <div class="col-sm-2 class-center p-0">
                <div v-show="index == checkShow" style="float: right">
                  <i
                    class="fas fa-chevron-down"
                    style="margin: auto; color: #0097a7"
                  ></i>
                </div>
                <div v-show="index != checkShow" style="float: right">
                  <i
                    class="fas fa-chevron-up"
                    style="margin: auto; color: #0097a7"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- content -->
        <div
          class="col-sm-12 pt-3"
          v-if="index == checkShow"
          style="font-size: 14px"
        >
          <!-- <div class="col-12"> -->
          <div class="row">
            <!-- left -->
            <div class="col-sm-6 pr-0">
              <table class="table table-borderless" id="table_summary_content">
                <tbody>
                  <tr>
                    <td>{{ $t("summary.annunciator") }}</td>
                    <td style="color: #ff4081">
                      {{ item.user_create.full_name }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("summary.status") }}</td>
                    <td style="color: #26ba50">{{ $t(item.status) }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("summary.reportTime") }}</td>
                    <td>{{ item.time_create }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("summary.assignTask") }}</td>
                    <td>
                      <div
                        v-for="(task_info, index) in item.task"
                        :key="task_info + index"
                        class="pb-1"
                      >
                        <div class="text-white" style="display: flex">
                          <i
                            v-if="task_info.status == 0"
                            class="fas fa-history mr-1"
                            style="color: #000000; font-size: 17px"
                          ></i>
                          <i
                            v-if="task_info.status == 1"
                            class="fas fa-check-circle mr-1"
                            style="color: #26ba50; font-size: 17px"
                          ></i>
                          <i
                            v-if="task_info.status == 2"
                            class="fas fa-pause-circle mr-1"
                            style="color: #006ad4; font-size: 17px"
                          ></i>
                          <a
                            class="pointer mission-content"
                            @click="showMissionInfo(task_info.id)"
                          >
                            {{ spliceString(task_info.content, 70) }}
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style>
                      <div
                        style="position: relative; display: inline-block"
                        v-if="
                          isSecretaryRole() ||
                          isChairmanRole()
                        "
                      >
                        <button
                          @click="addNewMission(item)"
                          type="button"
                          class="btn btn-button save-btn text-white"
                          style="
                            padding-left: 14px;
                            padding-right: 14px;
                            font-size: 12px;
                          "
                        >
                          {{ $t("summary.addMission") }}
                        </button>
                      </div>
                      <div
                        style="position: relative; display: inline-block"
                        v-if="
                          isSecretaryRole() ||
                          isChairmanRole()
                        "
                      >
                        <button
                          type="button"
                          class="btn btn-mission"
                          style="
                            padding-left: 22px;
                            font-size: 12px;
                            color: #26ba50;
                          "
                          @click="editSummaryInfo(item)"
                        >
                          {{ $t("summary.editSummary") }}
                        </button>
                        <img
                          src="../../assets/icons/edit.svg"
                          width="13px;"
                          style="position: absolute; top: 36%; left: 7px"
                        />
                      </div>
                      <div
                        style="position: relative; display: inline-block"
                        v-if="
                          isSecretaryRole() ||
                          isChairmanRole()
                        "
                      >
                        <button
                          type="button"
                          class="btn btn-mission"
                          style="
                            padding-left: 22px;
                            font-size: 12px;
                            color: #f2404d;
                            border: 1px solid #f2404d;
                          "
                          @click="showConfirmDelete(item)"
                        >
                          {{ $t("summary.deleteSummary") }}
                        </button>
                        <img
                          src="../../assets/icons/abort.svg"
                          width="10px;"
                          style="position: absolute; top: 42%; left: 7px"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- right -->
            <div class="col-sm-6">
              <div style="display: flex">
                <div class="col-sm-2 pl-2 pt-1 mt-2 font-weight-bold">
                  {{ $t("summary.document") }}:
                </div>
                <div class="col-sm-10">
                  <div
                    style="display: flex"
                    v-for="(documenItem, index) in item.document"
                    :key="documenItem + index"
                    class="row pb-2 mt-2"
                  >
                    <div
                      class="col-sm-9 p-0 class-center"
                      style="display: flex"
                    >
                      <div class="p-0 class-center item-center">
                        <img
                          :src="
                            documenItem['poster_url'] != null &&
                            documenItem['poster_url'] != ''
                              ? documenItem['poster_url']
                              : defaultPDF
                          "
                          style="width: 15px; height: 20px"
                          class="class-center"
                        />
                      </div>
                      <p class="pl-2 mb-0" style="word-break: break-all">
                        {{ documenItem.name }}
                      </p>
                    </div>
                    <div class="col-sm-3 p-0 class-center item-center">
                      <a :href="documenItem.url" download target="__blank">
                        <button
                          type="button"
                          class="btn mr-1"
                          style="
                            font-size: 12px;
                            border: 1px solid #26ba50;
                            color: #26ba50;
                            padding: 2px 7px;
                            font-size: 15px;
                            width: 32px;
                            height: 27px;
                          "
                        >
                          <i class="fas fa-file-download"></i></button
                      ></a>
                      <button
                        type="button"
                        class="btn"
                        style="
                          font-size: 12px;
                          border: 1px solid #26ba50;
                          color: #26ba50;
                          padding: 2px 7px;
                          font-size: 15px;
                          width: 32px;
                          height: 27px;
                        "
                        @click="viewDocument(documenItem)"
                      >
                        <i class="fas fa-eye"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
    <div class="row" v-if="showSummaryDocument">
      <div class="col-12 mt-3">
        <button
          type="button"
          class="btn save-btn text-white"
          style="font-size: 14px; color: #26ba50; padding: 4px 15px"
          @click="showSummaryDocument = false"
        >
          {{ $t("summary.returnPreviousPage") }}
        </button>
        <button
          v-if="isChairmanRole() || isSecretaryRole()"
          type="submit"
          class="btn save-btn text-white"
          style="font-size: small; color: #26ba50; padding: 4px 15px; margin-left: 5px"
          @click="updateDocumentReviewer()"
        >
          {{ $t('document.updateDocumentReviewer') }}
        </button>
        <button
          v-if="isHasPermSignature()"
          type="submit"
          class="btn save-btn text-white"
          style="font-size: small; padding: 4px 15px; margin-left: 5px"
          @click="exc_sign_file()"
        >
          {{ $t("document.signTheNumber") }}
        </button>
      </div>
      <div class="col-12 p-0 pt-3">
        <embed
          v-if="summaryDocumentData != null && summaryDocumentData != ''"
          :src="summaryDocumentData"
          type="application/pdf"
          frameborder="1"
          scrolling="auto"
          height="100%"
          width="100%"
          style="min-height: 650px"
        />
      </div>
    </div>
    <div v-show="false">
      <summaryTemplateDoc
        :summaryList="this.summaryList"
        ref="summaryTemplateDoc"
      />
    </div>
    <confirm-modal
      ref="confirmDeleteSumary"
      :content="confirmModalContent"
      @onConfirm="deleteSummary()"
    ></confirm-modal>
    <!--  -->
    <confirm-modal
      ref="confirmSendEmailForMissionNotComplete"
      :content="confirmModalSendForTask"
      @onConfirm="onClickSendEmail()"
    ></confirm-modal>
    <!--  -->
    <b-modal
      no-close-on-backdrop
      centered
      :hide-header="true"
      :hide-footer="true"
      id="modal-add-document"
      ref="modal-add-document"
      style="padding: 0"
    >
      <div class="modal-header">
        <h5 class="modal-title">{{ $t("summary.addDocument") }}</h5>
      </div>
      <div class="container p-0 mt-2 mb-2">
        <div>
          <div
            class="event-item row"
            style="
              display: flex;
              justify-content: space-between;
              margin: 5px 0 5px 0;
              padding: 10px 15px 10px 15px;
              cursor: pointer;
            "
          >
            <div class="col-12 pb-3">
              <span>{{ $t('document.currentDigitalSigner') }}: </span>
              <VueMutiselect
                ref="approverMutiSelect"
                class="mt-3"
                :optionData="userInEventList"
                :customLabel="customLabel"
                :labelText="labelTextValue"
                :trackByText="trackByTextValue"
                :placeHolder="$t('mission.selectPerson')"
                @select="selectUser"
              ></VueMutiselect>
            </div>
            <div class="col-12">
              <span>{{ $t('summary.conclusionSelectDocument') }}</span>
              <!--  -->
              <label for="upload-file-for-summary" class="custom-file-upload">
                <i
                  class="fas fa-file-upload"
                  style="left: 10px; top: 30%; color: #26ba50; margin-right: 10px"
                ></i>
                <span style="font-size: small; word-wrap: break-word;">{{
                  documentFileName ? documentFileName : $t("document.uploadNewDocument")
                }}</span>
              </label>
              <input v-on:change="onFileChange" id="upload-file-for-summary" type="file" accept="application/pdf"/>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn"
          @click="onCloseModalAddDocument"
        >{{ $t("summary.close") }}</button>
        <button
          type="button"
          class="btn save-btn text-white"
          @click="confirmUploadFile"
        >{{ $t("summary.confirm") }}</button>
      </div>
    </b-modal>
    <!--  -->
    <reviewerModal ref="reviewerModal"></reviewerModal>
    <summaryDetail ref="summaryDetail"/>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Avatar from "vue-avatar";
import AddNewSummary from "Components/AddNewSummary";
import summaryDetail from "./SummaryDetail"
import dateUtils from "utils/dateUtils";
import stringUtils from "utils/stringUtils";
import defaultPDF from "enum/summary";
import ConfirmModal from "Components/ConfirmModal";
import functionUtils from "utils/functionUtils";
import missionStatus from "enum/missionStatus";
import SummaryTemplateDoc from "./SummaryTemplateDoc";
import Enum from "enum/enum";
import axios from "axios";
import VueMutiselect from "Components/VueMutiselect";
import ReviewerModal from 'Components/AssignReviewerModal'

export default {
  data: () => ({
    confirmModalContent: "summary.confirmDeleteSummary",
    checkShow: 0,
    summaryList: [],
    listSummaryStatus: stringUtils.SummaryStatus,
    defaultPDF: defaultPDF,
    showSummaryDocument: false,
    summaryDocumentData: "",
    summaryCallBackId: null,
    checkDisableBtnSendEmail: true,
    sendEmailMissionData: null,
    confirmModalSendForTask: "summary.confirmSendEmailForTask",
    // upload document
    documentFileName: null,
    documentFileSize: null,
    mediaServerToken: null,
    mediaServerDomain: null,
    conclusion_id: null,
    userInEventList: [],
    labelTextValue: "full_name",
    trackByTextValue: "full_name",
    userInEventSelected: [],
    documentSelected: null
  }),
  components: {
    summaryTemplateDoc: SummaryTemplateDoc,
    avatar: Avatar,
    addnewsummary: AddNewSummary,
    ConfirmModal,
    VueMutiselect,
    reviewerModal: ReviewerModal,
    summaryDetail
  },
  watch: {
    GET_SUMMARY_LIST_DATA: function () {
      this.summaryList = [];
      if (
        this.GET_SUMMARY_LIST_DATA.length == 0 &&
        (
          this.isSecretaryRole() ||
          this.isChairmanRole())
      ) {
        this.$refs.showAddNewSummary.onShowAddNewSummary();
        this.checkShow = -1;
      }
      this.GET_SUMMARY_LIST_DATA.forEach((data, index) => {
        let assigner_name =
          data.user_create.last_name + " " + data.user_create.first_name;
        let item = {
          id: data.id,
          content: data.content,
          // status: this.getSummaryStatus(data.status),
          status: this.listSummaryStatus
            .filter((x) => x.value === data.status)
            .map((x) => x)[0].text,
          time_create: dateUtils.formatDate(
            data.date_created,
            dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
            "HH:mm DD/MM/YYYY"
          ),
          user_create: {
            id: data.user_create.id,
            full_name: assigner_name,
            url_avatar:
              data.user_create.url_avatar != null || ""
                ? data.user_create.url_avatar
                : null,
          },
          task: data.task,
          document: data.document,
          done_all_task: data.done_all_task,
          summaryStatus: data.status,
        };
        this.summaryList.push(item);

        if (
          this.summaryCallBackId != null &&
          this.summaryCallBackId == data.id
        ) {
          this.checkShow = index;
        }
      });
      this.updateStatusSummary(this.summaryList);
    },
    GET_TOKEN_MEDIA_SERVER_DATA: function() {
      this.mediaServerToken = this.GET_TOKEN_MEDIA_SERVER_DATA.data.token;
      this.mediaServerDomain = this.GET_TOKEN_MEDIA_SERVER_DATA.data.domain;
    },
    GET_USER_IN_EVENT_LIST_DATA: function() {
      this.userInEventList = [];
      if (!functionUtils.isNull(this.GET_USER_IN_EVENT_LIST_DATA)) {
        this.GET_USER_IN_EVENT_LIST_DATA.forEach((data) => {
          let full_name = data.lastname + " " + data.firstname;
          let item = {
            id: data.id,
            full_name: full_name,
            url_avatar:
              data.url_avatar != null || data.url_avatar != ""
                ? data.url_avatar
                : null,
            is_group: data.is_group
          };
          this.userInEventList.push(item);
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      "GET_SUMMARY_LIST_DATA",
      "DRAWER_CHANGE",
      "GET_CURRENT_ACCOUNT",
      "GET_TOKEN_MEDIA_SERVER_DATA",
      "GET_USER_IN_EVENT_LIST_DATA",
      "EVENT_DETAIL_DATA"
    ]),
  },
  methods: {
    isSecretaryRole: function () {
      return functionUtils.isSecretaryRole(this.GET_CURRENT_ACCOUNT);
    },
    isChairmanRole: function () {
      return functionUtils.isChairmanRole(this.GET_CURRENT_ACCOUNT);
    },
    showContentSummary(index) {
      if (index == -1) {
        this.$refs.showAddNewSummary.onShowAddNewSummary();
        this.checkShow = index;
      } else {
        this.$refs.showAddNewSummary.onClickCancel();
        this.checkShow = index;
      }
    },
    editSummaryInfo(data) {
      this.$refs.summaryDetail.onShowModalConfirm(data)
    },
    addNewMission(itemData) {
      this.$router.push({
        name: "mission.title",
        query: {
          summaryId: itemData.id,
        },
      });
    },
    showConfirmDelete(item) {
      this.$refs.confirmDeleteSumary.onShowModalConfirm();
      this.summaryDelete = item;
    },
    deleteSummary() {
      this.DELETE_SUMMARY(this.summaryDelete)
        .then(
          function () {
            let filter = {
              params: {
                event: this.event_id,
              },
            };
            this.GET_SUMMARY_LIST(filter);
            this.ON_SHOW_TOAST({
              message: "Success",
              styleType: "success",
            });
          }.bind(this)
        )
        .catch(
          function (error) {
            this.ON_SHOW_TOAST({
              message: error.response.data.message,
              styleType: "danger",
            });
          }.bind(this)
        );
    },
    viewDocument(documentData) {
      this.documentSelected = documentData
      this.showSummaryDocument = true;
      this.summaryDocumentData = functionUtils.validateStringIsNull(documentData.url) ? null : documentData.url ;
    },
    showMissionInfo(id) {
      this.$router.push({
        name: "mission.title",
        query: {
          missionDetailId: id,
        },
      });
    },
    spliceString(content, length) {
      return functionUtils.spliceString(content, length);
    },
    onClickSendEmail() {
      let task = this.sendEmailMissionData.task;
      let taskList = task.filter((x) => x.status == 0);
      if (taskList.length > 0) {
        let taskdata = {
          task: taskList,
          conclusion: this.sendEmailMissionData.id,
          site_name: this.$t("common.appTitle"),
          event: this.event_id,
        };
        this.SEND_EMAIL_FOR_MISSION_NOT_DONE(taskdata)
          .then(
            function () {
              this.ON_SHOW_TOAST({
                message: "Success",
                styleType: "success",
              });
            }.bind(this)
          )
          .catch(
            function (error) {
              this.ON_SHOW_TOAST({
                message: error.response.data.message,
                styleType: "danger",
              });
            }.bind(this)
          );
      }
    },
    updateStatusSummary(summaryList) {
      summaryList.forEach((data) => {
        let checkTaskDone = data.task.filter(
          (x) => x.status != missionStatus.DONE
        );
        if (checkTaskDone.length != 0 && data.summaryStatus == 0) {
          let summaryStatusWaiting = {
            id: data.id,
            status: 1,
          };
          data.status = this.$t("summary.waitingUpdate");
          this.UPDATE_STATUS_SUMMARY(summaryStatusWaiting);
        } else if (checkTaskDone.length == 0 && data.summaryStatus == 1) {
          let summaryStatusDone = {
            id: data.id,
            status: 0,
          };
          data.status = this.$t("summary.done");
          this.UPDATE_STATUS_SUMMARY(summaryStatusDone);
        }
      });
    },
    showModalConfirmSendEmailForMissionNotComplete(data) {
      this.sendEmailMissionData = data;
      this.$refs.confirmSendEmailForMissionNotComplete.onShowModalConfirm();
    },
    onShowModalAddDocument(item) {
      this.conclusion_id = item
      this.$refs["modal-add-document"].show();
    },
    onFileChange: function(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.documentFileName = files[0].name;
      this.documentFileSize = files[0].size;
      this.getResultFile(files[0]);
    },
    getResultFile: function(file) {
      let vm = this;
      vm.documentFile = file;
    },
    confirmUploadFile: function(){
      if (functionUtils.isNull(this.documentFileName)) {
        this.ON_SHOW_TOAST({
          'message': this.$t('document.fieldRequire.chooseDocument'),
          'styleType': 'danger'
        })
      }else{
        this.uploadFileToServer()
      }
    },
    uploadFileToServer: function() {
      let extensionsFile = functionUtils.getFileExtensions(this.documentFileName)
      // Invalid if file bigger 10MB
      if (this.documentFileSize > 10000000) {
        this.ON_SHOW_TOAST({
          message: this.$t("document.cannotExceed10MB"),
          styleType: "danger"
        });
      } else if (extensionsFile.toLowerCase().trim() != 'pdf') {
        this.ON_SHOW_TOAST({
          message: this.$t('document.plsUploadPdfFile'),
          styleType: "danger"
        });
      } else {
        let formData = new FormData();
        formData.append("file", this.documentFile);
        formData.append("directory", "documents/" + this.event_id);
        axios({
          method: "POST",
          timeout: 6000,
          url: this.mediaServerDomain + "/api/v1/upload/",
          data: formData,
          headers: { Authorization: `JWT ${this.mediaServerToken}` }
        })
          .then(
            function(res) {
              let url = res.data.domain + res.data.url
              let filter = {
                name: this.documentFileName,
                url: url,
                event: this.event_id,
                document_status: 1,
                conclusion_id: this.conclusion_id,
                digital_signer: this.userInEventSelected
              }
              this.SAVE_DOCUMENT_FOR_CONCLUSION(filter).then(
                function () {
                  this.ON_SHOW_TOAST({
                    message: this.$t("common.success"),
                    styleType: "success"
                  });
                  this.getResultFile(null);
                  // 
                  let filter = {
                    params: {
                      event: this.event_id
                    }
                  };
                  this.GET_SUMMARY_LIST(filter);
                  this.$refs["modal-add-document"].hide();
                }.bind(this)
              ).catch(
                function () {
                  this.ON_SHOW_TOAST({
                    message: this.$t("common.somethingWentWrong"),
                    styleType: "danger"
                  });
                }.bind(this)
              )
            }.bind(this)
          )
          .catch(
            function() {
              this.ON_SHOW_TOAST({
                message: this.$t("common.somethingWentWrong"),
                styleType: "danger"
              });
            }.bind(this)
          );
      }
    },
    onCloseModalAddDocument(){
      this.getResultFile(null);
      this.$refs["modal-add-document"].hide();
    },
    // Socket
    webSocketSummary: function () {
      const ws_scheme = window.location.protocol == "https:" ? "wss" : "ws";
      const backEndUrl = new URL(process.env.VUE_APP_BACKEND_URL);
      const socket = new WebSocket(
        ws_scheme +
          "://" +
          backEndUrl.host +
          `/${Enum.ChannelsActionCodeEnum.Conclusion}/`
      );
      socket.onmessage = function (event) {
        if (event.data != undefined) {
          let message_data = JSON.parse(event.data);
          if (message_data.message == Enum.ActionEnum.Conclusion) {
            const customer_data = {
              params: {
                event: this.event_id,
              },
            };
            this.GET_SUMMARY_LIST(customer_data);
          }
        }
      }.bind(this);

      socket.onclose = function () {
        // this.connected = false
      };
    },
    /**
     * Customize label
     */
    customLabel({ full_name }) {
      return `${full_name}`;
    },
    /**
     * Get uploader
     */
    selectUser(data) {
      this.userInEventSelected = []
      data.forEach((item) => {
        this.userInEventSelected.push({ id: item.id, is_group: item.is_group });
      });
    },
    updateDocumentReviewer: function () {
      let isUpdate = true
      this.$refs.reviewerModal.onShowModalConfirm(this.documentSelected.id, isUpdate, this.documentSelected.name)
    },
    /**
     * Check has perm signature
     */
    isHasPermSignature: function () {
      if (this.isSecretaryRole() 
        || this.isChairmanRole() 
        || this.GET_CURRENT_ACCOUNT.id == this.documentSelected.ownerId 
        || !functionUtils.isNull(this.documentSelected.current_digital_signer)) {
        return true
      } else {
        return false
      } 
    },
    /**
     * Sign document file
     */
    exc_sign_file() {
      var prms = {};
      var metaArray = [{"Key":"token", "Value": this.mediaServerToken}];
      prms["FileUploadHandler"] = process.env.VUE_APP_SIGNED_FILE_ULR;
      prms["SessionId"] = "";
      prms["FileName"] = this.documentSelected.url;
      prms["MetaData"] = metaArray;
      var json_prms = JSON.stringify(prms);
      // eslint-disable-next-line no-undef
      vgca_sign_file(json_prms, this.SignFileCallBack);
    },
    /**
     * Sign file callback
     */
    SignFileCallBack(rv) {
      var received_msg = JSON.parse(rv);
      if (received_msg.Status == 0) {
        let documentFilter = {
          documentSignedUrl: received_msg.FileServer
        }
        this.SIGNED_DOCUMENT({ id: this.documentSelected.id, ...documentFilter }).then(
          function () {
            this.ON_SHOW_TOAST({
              'message': this.$t('common.success'),
              'styleType': 'success'
            })
          }.bind(this)
        ).catch(
          function () {
            this.ON_SHOW_TOAST({
              'message': this.$t('common.somethingWentWrong'),
              'styleType': 'danger'
            })
          }.bind(this)
        )
      } else {
        this.ON_SHOW_TOAST({
          'message': this.$t('common.somethingWentWrong'),
          'styleType': 'danger'
        })
      }
    },
    ...mapActions([
      "GET_SUMMARY_LIST",
      "DELETE_SUMMARY",
      "ON_SHOW_TOAST",
      "SEND_EMAIL_FOR_MISSION_NOT_DONE",
      "UPDATE_STATUS_SUMMARY",
      "SAVE_DOCUMENT_FOR_CONCLUSION",
      "GET_USER_IN_EVENT_LIST"
    ]),
  },
  mounted() {
    if (this.$route.query.summaryCallBackId != undefined) {
      this.summaryCallBackId = parseInt(this.$route.query.summaryCallBackId);
    }
    let event_id = sessionStorage.getItem("event_id");
    this.event_id = event_id;
    let filter = {
      params: {
        event: event_id,
      },
    };
    this.GET_SUMMARY_LIST(filter);
    this.webSocketSummary();
    let attendesSpeakersFilter = {
      params: {
        event: this.event_id,
        bandGroup: true
      }
    };
    this.GET_USER_IN_EVENT_LIST(attendesSpeakersFilter)
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.class-center {
  align-self: center;
}
.item-center {
  text-align: center;
}
// .avatar-icon {
//   width: 30px;
//   height: 30px;
// }
.select {
  background: #efecec;
}
.not-select {
  border-top: 1px solid #a09f9f;
  border-bottom: 1px solid #a09f9f;
}
.pointer {
  cursor: pointer;
}
table#table_summary_content td:nth-child(1) {
  width: 20%;
  white-space: nowrap;
}
.btn-mission {
  border: 1px solid #26ba50;
  padding: 2px 10px;
  margin-right: 15px;
  margin-top: 5px;
}
.btn-button {
  padding: 2px 10px;
  margin-right: 15px;
  margin-top: 5px;
}
.save-btn {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
}
.circular--portrait {
  position: relative;
  max-width: 52px;
  max-height: 52px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.circular--portrait img {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.mission-content {
  background: #26ba50;
  padding: 1px 7px;
  border-radius: 0.25rem;
  font-size: 12px;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 25px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}
input[type="file"] {
  display: none;
}
</style>