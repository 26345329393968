import { render, staticRenderFns } from "./SummaryDetail.vue?vue&type=template&id=705d4419&scoped=true&"
import script from "./SummaryDetail.vue?vue&type=script&lang=js&"
export * from "./SummaryDetail.vue?vue&type=script&lang=js&"
import style0 from "./SummaryDetail.vue?vue&type=style&index=0&id=705d4419&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "705d4419",
  null
  
)

export default component.exports